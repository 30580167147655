/* SALIR.CSS */

.containerSalir {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: 50% auto;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}


h4 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Estilos para pantallas de celular */
@media (max-width: 480px) {
  .containerSalir {
    margin-top: 15vh;
    flex-direction: column;
  }
}
