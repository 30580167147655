.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .cabecera {
    text-align: center;
    padding: 20px 0;
    display: flex;
    align-items: center; /* Centra verticalmente el contenido */
    justify-content: center;
  }
  
  .cabecera h1 {
    font-size: 36px;
    color: #0c5da1;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .cursosGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .cursoCard {
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    transition: transform 0.2s;
  }
  
  .cursoCard:hover {
    transform: scale(1.05);
  }
  
  .cursoImagen {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .cursoTitulo {
    font-size: 24px;
    color: #333;
    margin-top: 10px;
  }
  
  .cursoDescripcion {
    font-size: 16px;
    color: #777;
  }
  
  .cursoInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .cursoDetalle {
    display: flex;
    align-items: center;
    color: #0c5da1;
  }
  
  .cursoDetalle i {
    margin-right: 5px;
  }
  
  .boton-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0c5da1;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, transform 0.2s;

  }
  
  .boton-link:hover {
    background-color: #fff;
    color: #0c5da1;
    transform: scale(1.05);
  }
  
  
  .footer {
    text-align: center;
    margin-top: 40px;
    padding-top: 20px;
    background-color: #1cd10e;
    color: #fff;
    font-size: 18px;
    border-top: 1px solid #ddd;
  }
  