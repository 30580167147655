/* descarga.css */
.contenedorDescarga {
  display: flex;
  justify-content: center;
}

.DashtituloDescarga {
  max-width: 800px;
  margin: 30px;
}

.divDescarga {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
}

h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.archivoDescarga {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

i {
  font-size: 20px;
  margin-right: 10px;
}

a {
  text-decoration: none;
  color: #333;
}

p {
  margin: 0;
}
.archivos-container {
  display: table;
  border-collapse: collapse;
  width: 100%;
}

.archivoDescarga {
  display: table-row;
}


.archivoDescarga > * {
  display: table-cell;
  border-bottom: 1px solid black;
  padding: 5px;

}

.archivoDescarga a {
  text-decoration: none;
  color: black;
  vertical-align: middle;
}

.archivo-info {
  vertical-align: middle;
}

.archivo-nombre {
  font-weight: bold;
  vertical-align: middle;
  text-align: left;
}

.archivo-descripcion {
  margin-top: 5px;
  vertical-align: middle;
  text-align: left;
}
.footer {
  position: fixed;
  bottom: 20;
  width: 80%;
  z-index: 9999;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .DashtituloDescarga {
    margin: 10px;
  }

  h3 {
    font-size: 20px;
  }

  .archivoDescarga {
    flex-direction: column;
    align-items: flex-start;
  }

  i {
    margin-right: 0;
  }
}