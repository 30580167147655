/* div de los cuadros estadisticos de maxima - minima y promedio*/
.container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 100%;
  /* Estilos adicionales para cada div de contenido */
  text-align: center;
}

@media (min-width: 480px) {
  .item {
    width: 33.33%;
  }
  .itemBotonera {
    width: 90%;
  }
}

/*----*/
.itemBotonera {
  width: 25%;
  /* Estilos adicionales para cada div de contenido */
  text-align: center;
}
/* -- */

.itemgrafica {
  width: 100%;
  height: 250px;
}
.itemlast {
  width: 100%;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 90%; /* Ajusta la altura al 100% del viewport */
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  border: none;
}

.iframe-container iframe body {
  transform-origin: top left;
  transform: scale(0.75); /* Modifica este valor para ajustar el tamaño */
}

.usuarios2ppal{
  overflow-x: hidden;
  overflow-y: hidden; /* Agrega una barra de desplazamiento vertical si es necesario */
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #0e130dab;
  /*background-image: url("../../../public/img/dashboard/dashboard_fondoGreen.jpg");*/
}
 
.loginCard {
  background-color: #00ff4c31; /* centrar vertical y horizontalmente */
  position: absolute;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0px 0 0 0px; /* aplicar a top y al margen i*/
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;
}

.loginCampo {
  position: relative;
  overflow: hidden;
  text-align: center;
  align-items: center;
}

/* el spin de la pantalla usuarios2*/
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Ajusta el nivel de transparencia aquí */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  background-color: rgba(255, 255, 255, 0.11);
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
 /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);  Ajusta la sombra según tus preferencias */
}


.footerLogin {
  justify-content: center;
  text-align: center;
  color: rgb(73, 73, 73);
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.footerLog {
    text-align: center;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #1cd30f;
    color: #fff;
    border-top: 1px solid #ddd;
}

/*******Dashboard USUARIO Dashboard USUARIO Dashboard USUARIO Dashboard USUARIO *****************/
.fondoDash {
  /*background-image: url('../../../public/img/dashboard/dashazulyverde.jpg');  Ruta de la imagen */
   /* background-repeat: no-repeat;No repetir la imagen */
  background-position: center; /* Centrar la imagen en el botón */
  background-size: cover; /* Ajustar tamaño de la imagen al botón */ 
  
}

.centrado {
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.centra3div {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.centradiv1 {
  align-self: center;
  margin: auto;
}

.centro {
  justify-content: center;
  align-items: center;
  text-align: center;
  float: center;
  position: relative;
  margin: 0 auto;
}

.boton-salir {
  background-color: transparent;
  border: none;
  position: relative;
}

.boton-salir:hover::after {
  content: "SALIR";
  position: absolute;
  top: 100%;
  left: 50%; 
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
}

.imagen-salir {
  width: 40px; /* Ajusta el ancho según tus necesidades */
  height: 40px; /* Ajusta la altura según tus necesidades */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Ajusta la altura según tus necesidades */
}

.chart-container {
  width: 100%;
  /* Ajusta la altura según tus necesidades */
}
