.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.form-section {
  grid-column: span 2;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  background: #f9f9f9;
}

.form-section h4 {
  margin-bottom: 12px;
  font-size: 1.2em;
  color: #333;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.btn-save {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-save:hover {
  background-color: #45a049;
}

.btn-cancel:hover {
  background-color: #d32f2f;
}

/* Contenedor para manejar el scroll horizontal */
.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
}

/* Estilo general para la tabla */
.data-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Garantiza que las columnas tengan el mismo tamaño */
  font-family: 'Arial', sans-serif;
}

.data-table th,
.data-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap; /* Evita que el texto se envuelva, importante para scroll */
}

.data-table th {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  text-align: center;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}

.data-table td {
  font-size: 0.9em;
  color: #555;
}

/* Estilo de las filas cuando se pasa el mouse */
.data-table tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* Mejorar la visibilidad de las celdas */
.data-table td {
  overflow: hidden;
  text-overflow: ellipsis; /* Asegura que los textos largos sean cortados y mostrados con '...' */
}

/* Estilo de las cabeceras */
.data-table th {
  font-size: 1em;
  padding: 16px;
  text-align: center;
  background-color: #2196F3;
  color: white;
}

/* Hacer que la tabla sea responsive */
@media (max-width: 768px) {
  .data-table {
    font-size: 0.8em;
  }

  .data-table th,
  .data-table td {
    padding: 8px;
  }
}
