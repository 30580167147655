.section-title {
    margin-bottom: 70px;
  }
  
  .section-title h2 {
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  
  .section-title h2::after {
    position: absolute;
    content: "";
    background: linear-gradient(to right, #0284fd 0%, #00ff2a 100%);
    height: 4px;
    width: 100%;
    bottom: 0;
    margin-left: 0px;
    left: 0%;
  }
  
  .section-title p {
    font-size: 18px;
  }

  .section-title i {
    margin-right: 20%;
  }

  .container {
    display: flex;
  }
  
  .left-div {
    flex: 80%;
    padding: 20px;
  }
  
  .right-div {
    flex: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
  }
  
  .exit-button {
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .exit-button i {
    margin-left: 5px;
  }



  .section-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
     }
  
  .section-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 5px; /* Ajusta el espacio horizontal entre los botones */
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .section-button i {
    margin-right: 10px;
  }
  
  .section-button:hover {
    background-color: #0056b3;
  }

  .fondoAzul{
    background-color:"#007bff";
    color:"white";
    text-align: "center";
  }
  