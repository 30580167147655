@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #header,
  #about,
  #cursos,
  #codigo,
  #testimonials,
  #contact  
  /* #team, */
  /* #contact,
  #blog,
  #IndexBlog */
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 100%;
  }
}

