.contenedorUsuario {
  display: flex;
  flex-wrap: wrap;
  /* color: #6e6e6e; */
  height: auto;
  color:#414141;
}

.contenedorUsuario > div {
  flex: 1 0 calc(100% - 5px);
  padding: 0px;
}

.DashtituloUsuario {
  text-align: left;
  margin: 1px;
}

.divUsuario {
  border: 2px solid #ffffff00;
  /* background-color: #91919163; */
  padding: 0px;
  margin-top: 0px;
  width: 99%;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.ultimosdatos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  
}
.ultimosdatos h2{
  color:#222236c9;
}

/* .itemultimosdatos {
  margin: 10px;
  border: 1px solid #000000;
  width: 45%;
  padding-bottom: 5px;
  
} */
.itemultimosdatos {
  margin: 5px;
  border: 1px solid #053cf3; /* Cambiado a un gris oscuro */
  /* background-color: rgb(158, 153, 153); Fondo en gris más claro */
  width: 45%;
  padding: 10px; /* Aumentado el espacio interno */
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0, 255, 42, 0.582); /* Agregado un sombreado suave */
}

.itemultimosdatos strong {
  font-size: 40px;
  margin-left: 10px;
  
}

.chart-container {
  width: 100%;
  height: 100px; /* Ajusta la altura del gráfico según tus necesidades */
  border: 1px solid #000000;
  
}

.arrow-image {
  margin-left: 15px;
  width: 60px;
  height: 70px;
}

.EnchufeImage {
  font-size: 60px;
}

.LastReg {
  font-size: 24px;
  color: #000000;
  display: flex;
  justify-content: center;
  width: 93%;
  margin: 10px 25px;
  align-items: center;
  justify-content: center;
  border: 2px solid #000000;
}

.LastReg u{
  margin-top: 10px;
}
.LastReg strong{
  margin-top: 10px;
  font-size: 24px;
}

.footVerde {
  background-color: #1CD10E;
}
.footVerde p{
  text-align:center;
  font-size: 24px;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .contenedorUsuario {
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }

  .contendorUsuario > div {
    flex: 1 0 auto;
    padding: 10px;
  }

  h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .itemUsuario {
    margin-top: 10px;
    padding: 10px;
  }

  p {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .itemultimosdatos {
    margin: 3px;
    padding: 3px;
  }

  .itemultimosdatos h2 {
    font-size: 16px;
    align-self: auto;
  }

  .chart-container {
    height: 150px; /* Ajusta la altura del gráfico según tus necesidades */
  }

  .itemultimosdatos strong {
    font-size: 14px;
    margin-left: 3px;
  }

  .chart-container .recharts-responsive-container {
    width: 100%;
    height: 100%;
  }
  .arrow-image {
    width: 20px;
    height: 20px;
  }

  .EnchufeImage {
    font-size: 30px;
  }
  .LastReg {
    font-size: 16px;
    color: "#000000";
    width: 93%;
    margin: 7px 10px;
  }
  .footVerde p{
    text-align:center;
    font-size: 14px;
    
  }
}
