/* registros.css */

.containerRegistros {
  display: flex;
  justify-content: center;
  height: 100vh;
}

h4 {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
.container-loading {
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.centered-table {
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

.green-irriga {
  background-color: green;
}

.red-irriga {
  background-color: red;
}

.divspin {
  width: 95%;
}

.containerReg {
  align-items: center;
  text-align: center;
  width: 100%;
}

.container table {
  text-align: center;
  align-items: center;
}
.container table.thead.td {
  text-align: center;
}
.container th {
  text-align: center;
}
.container td {
  text-align: center;
}

/* Estilos para pantallas de celular */
@media (max-width: 480px) {
  .containerRegistros {
    margin-top: 15vh;
    flex-direction: column;
  }
}
