
.comentando-form {
  margin: 0 auto;
  max-width: 90%; /* Cambiamos el ancho máximo para que se adapte mejor en pantallas pequeñas */
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.form-title {
  font-size: 24px;
  color: #0c5da1;
  margin-bottom: 20px;
}

.comentando-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permite que los botones se envuelvan en pantallas pequeñas */
}

.button-secondary,
.button-primary {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px; /* Añadimos margen para separar los botones en pantallas pequeñas */
}

.button-secondary {
  background-color: #ccc;
  color: #333;
  transition: background-color 0.3s;
}

.button-primary {
  background-color: #0c5da1;
  color: #fff;
  transition: background-color 0.3s;
}

.button-primary:hover {
  background-color: #0770c9;
}