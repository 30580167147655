/* div de los cuadros estadisticos de maxima - minima y promedio*/
.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
}

.item {
  max-width: 30%;
  /* Estilos adicionales para cada div de contenido */
  text-align: center;
  border-radius: 20px; /* Radio de redondeo de 20px para todas las esquinas */
  margin-right: 3%; /* Margen a la derecha de 5px */
  margin-top: 10px;
  box-shadow: 5px 5px 5px #454e4794; /* Agregado un sombreado suave */
}

.footVerde {
  background-color: #1CD10E;
}
.footVerde p{
  text-align:center;
  font-size: 24px;
  color: #FFFFFF;
}
/*datepicker*/
.divdatepicker {
  display: flex; /* Usa un flex container para alinear los elementos */
  align-items: center;
  max-width: 90%;
  max-height: 500px;
  justify-content: center;
  text-align: center;
  /*background-color: #1CD10E;*/
  
}

/* Estilos para los campos de fecha */
.date-picker {
  font-size: 14px;
  padding: 5px; /* Ajusta el padding para dar espacio alrededor del campo */
  max-width: 110px;
  white-space: nowrap; /* Evita el salto de línea en el campo de fecha */
  margin-left: 5px;
}

.spandsd{
  margin-left: 5px;
  color: #000;
}

.spanhst{
  margin-left: 15px;
  color: #000;
}
/* Estilos para el calendario desplegable */
.react-datepicker {
  font-size: 14px;
  max-width: 300px; /* Ajusta el ancho del calendario desplegable */
}
.react-datepicker__current-month {
  font-size: 14px; /* Ajusta el tamaño del mes en el calendario */
}

.btnsbuscar {
  background-color: #007bff; /* Color de fondo */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  padding: 5px 5px; /* Espacio alrededor del texto */
  font-size: 14px; /* Tamaño del texto */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  border-radius: 5px; /* Bordes redondeados */
  margin-left: 10px;
  width: 100px;
}

/* Cambia el estilo al pasar el mouse por encima */
.btnsbuscar:hover {
  background-color: #0056b3; /* Cambia el color de fondo */
}

/*cantidad de registros que se encontraron*/
.resaltar {
  color: rgb(0, 0, 0); /* Cambia el color del texto a resaltar */
  font-weight: bold; /* Hace que el texto sea más audaz */
}


@media (min-width: 480px) {
  .item {
    width: 33.33%;
    
  }
  .itemBotonera {
    width: 90%;
  }
}

/*----*/
.itemBotonera {
  width: 25%;
  /* Estilos adicionales para cada div de contenido */
  text-align: center;
}
/* -- */

.itemgrafica {
  margin-bottom: 30px;
  width: 100%;
  height: 300px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(35, 39, 36, 0.582); /* Agregado un sombreado suave */
  background-color: #9ab19a;
  color: #000;
  padding-top: 10px; /* Margen interior superior de 10px */
  padding-bottom: 10px;
}

.itemlast {
  width: 100%;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 90%; /* Ajusta la altura al 100% del viewport */
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  border: none;
}

.iframe-container iframe body {
  transform-origin: top left;
  transform: scale(0.75); /* Modifica este valor para ajustar el tamaño */
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;
  color: rgba(0, 0, 0, 0.76);
  /*background-image: url("../../../public/img/backimg/backlogin.png");*/
  
}

.loginCard {
  background-color: #00ff4c31; /* centrar vertical y horizontalmente */
  position: absolute;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0px 0 0 0px; /* aplicar a top y al margen i*/
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;
}

.loginCampo {
  position: relative;
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.footerLogin {
  justify-content: center;
  text-align: center;
  color: rgb(73, 73, 73);
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.footer {
  background-color: #00ff1500; /* centrar vertical y horizontalmente */
  justify-content: center;
  text-align: center;
}

/*******Dashboard USUARIO Dashboard USUARIO Dashboard USUARIO Dashboard USUARIO *****************/
.fondoDash {
  /*background-image: url('../../../public/img/dashboard/backgreendash.jpg');  Ruta de la imagen */
  background-color: rgba(255, 255, 255, 0);
  background-repeat: no-repeat; /* No repetir la imagen */
  background-position: center; /* Centrar la imagen en el botón */
  background-size: cover; /* Ajustar tamaño de la imagen al botón */
}


.centrado {
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.centra3div {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.centradiv1 {
  align-self: center;
  margin: auto;
}

.centro {
  justify-content: center;
  align-items: center;
  text-align: center;
  float: center;
  position: relative;
  margin: 0 auto;
}

.boton-salir {
  background-color: transparent;
  border: none;
  position: relative;
}

.boton-salir:hover::after {
  content: "SALIR";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
}

.imagen-salir {
  width: 40px; /* Ajusta el ancho según tus necesidades */
  height: 40px; /* Ajusta la altura según tus necesidades */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  /* Ajusta la altura según tus necesidades */
}

.chart-container {
  width: 90%;
  height: "250px";/* Ajusta la altura según tus necesidades */
  border: none;
}
