/* Estilo general */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Contenedor principal */
.blog-container {
  background-color: #fff;
  padding: 20px;
}

/* Título */
h1 {
  color: #0c5da1;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Cuadrícula de publicaciones */
.post-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Tarjeta de publicación */
.post-card {
  background-color: #fff;
  border: 1px solid #0c5da1;
  padding: 20px;
  width: 100%; /* Cambiamos el ancho a 100% para que sea de ancho completo en pantallas pequeñas */
  box-sizing: border-box;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}
.post-card:hover .post-content {
  color: #333; /* Color de texto normal al pasar el mouse */
  transform: none; /* Elimina cualquier transformación al pasar el mouse */
}

@media (min-width: 768px) {
  /* Cambiamos el ancho de la tarjeta de publicación a 48% en pantallas más grandes */
  .post-card {
    width: calc(48% - 10px);
  }
}

.post-card:hover {
  transform: scale(1.05);
}
.post-card:hover .post-content {
  color: #333; /* Color de texto normal al pasar el mouse */
  transform: none; /* Elimina cualquier transformación al pasar el mouse */
}

.post-content{
  color: #333; /* Color de texto normal al pasar el mouse */
  transform: none; /* Elimina cualquier transformación al pasar el mouse */
}

.post-image {
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 100%;
}

/* En tu archivo CSS personalizado (blog.css) */
.post-title-link {
  text-decoration: none;
  color: #333;
  transition: transform 0.3s;
}

.post-title-link:hover {
  text-decoration: underline; /* Add underline on hover */
  color: #CF9951; /* Change text color on hover */
  transform: translateY(-3px);
}


/* Pie de página */
footer {
  background-color: #1eff00;
  color: #333;
  text-align: center;
  padding: 20px;
}

/* Agregamos estilos para hacer el pie de página más responsive */
@media (max-width: 768px) {
  footer {
    font-size: 14px; /* Reducimos el tamaño de fuente en pantallas pequeñas */
  }
}
