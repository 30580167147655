.mensajeria-container {
  /* Tus estilos aquí */
}

.receptor-item {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.receptor-item:hover {
  background-color: #f0f0f0;
}

.receptor-nombre {
  font-weight: bold;
  text-transform: uppercase; /* Convertir a mayúsculas */
  color: #007bff; /* Color azul para destacar */
}

.mensaje-nuevo {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}
