/* flexscreen.css */
.flexscreen-container {
    padding: 20px;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .card {
    background-color: #fff;
    border: 2px solid #aca9a9;
    padding: 10px;
    width: 300px;
    box-shadow: 0 3px 15px #cccf038a;
    border-radius: 8px;
  }
  
  .card h3 {
    margin-top: 0;
  }
  
  .qr-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .colorverde{
    color: #6b8e23;
  }
  .centered-text {
    text-align: center;
  }


  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columnas de tamaño igual */
    gap: 20px; /* Espacio entre las columnas */
  }
  
  .card-body p {
    margin: 0;
    padding: 5px 0;
  }
  
  .card-body h4 {
    margin: 10px 0;
    font-size: 16px;
    color: #6b8e23;
  }
  
  .qr-container {
    text-align: center;
    margin-top: 20px;
  }