/* estilos.css */
.bodydash {
  font-family: 'Orbitron', sans-serif; /* Fuente futurista */
  background-color: #7e7d7d00; /* Fondo oscuro */
  color: #f0f0f0; /* Texto claro */
  margin: 0;
  padding: 0;
}

.containerdash {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 10px;
}

.carddash {
  background-color: #1377b9;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.card:hoverdash {
  transform: translateY(-10px);
}

.h2dash {
  font-size: 24px;
  margin-bottom: 10px;
  color: #00ff6a; /* Verde neón */
}

.pdash {
  font-size: 16px;
  line-height: 1.6;
  color: #f0f0f0;
}

#mapdash {
  grid-column: span 2;
  height: 400px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}
