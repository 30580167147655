/* vivero.css */

.contenedorVivero {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

h4 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
.itemlastVivero {
  border: 2px solid #d1fad000;
  background-color: #e9f5e800;
  padding: 20px;
  margin-top: 20px;
}
table { 
  border-collapse: collapse;
  width: 100%;
  height: auto;
}

.table-margin {
  margin-left: 1%;
  margin-right: 1%;
}

th,
td {
  padding: 8px;
  word-break: break-word;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
.footVerde {
  background-color: #1CD10E;
}
.footer {
  position: fixed;
  bottom: 20;
  width: 80%;
  z-index: 9999;
  text-align: center;
}

/* Estilos para pantallas de celular */
@media (max-width: 768px) {
  .containerVivero {
    flex-direction: column;
    height: auto;
  }

  .table {
    /* flex-direction: column;*/
    height: 100%; 
    flex: 1 0 auto;
      padding: 10px;
  }

  th,
  td {
    font-size: 12px;
  }
}
