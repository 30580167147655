.edit-form-center {
  display: flex;
  justify-content: center;
  margin-top: -200px; /* Agregando el margen superior */
}

.edit-form-container {
  border: 1px solid #ccc;
  padding: 20px;
  width: 350px;
  background-color: #409cff;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0, 255, 42, 0.582);
}


.form-field {
  margin-bottom: 10px;
}

.editable input {
  background-color: #b0f5da; /* Color de fondo rojo suave para campos editables */
}

.editable {
  background-color: #409cff; /* Color de fondo verde para campos editables */
}

.editable-modified {
  background-color: #ff9999; /* Color de fondo rojo suave para campos editables modificados */
}


.non-editable {
  background-color: #409cff; /* Color de fondo gris para campos no editables */
}

.warning-message {
  border: 1px solid #ff9999;
  background-color: #ffeeee;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warning-message button {
  margin-left: 10px;
}
