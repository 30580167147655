.dashboard {
  display: flex;
  height: 99%;
  overflow-y: hidden;
  overflow-y: auto;
}

.sidebar {
   width: 15%; /*ancho de la barra izquierda de menu */
   height: 99%;
   padding-top: 1%;
  background-color: #f2f2f2;
  transition: width 0.3s ease;
  overflow: hidden;
  overflow-y: none; /* Agrega un desplazamiento vertical si el contenido excede el tamaño del contenedor */
  text-align:left;
  margin-left: 25px;
}

.i{
  text-align: right;
}

.sidebar.expanded {
  width: 0%;
  
}



.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15%;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar.expanded ul {
  opacity: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.sidebar li.active {
  background-color: #9ac1fcb6;
}


.sidebar li:hover {
  background-color: #87d191d8;
}

.contentDash {
  flex: 1;
  padding: 3%;
  transition: margin-left 0.3s ease;
  background-color:  #948f8f9a ; /* color de fondo del TODAS las pantallas del dashboard*/
  /*background: linear-gradient(to right, #03afff 0%, #00ff22 100%);
  #35502b81; /* color de fondo del TODAS las pantallas del dashboard*/
  
  width: auto;
  height: auto; /* Ajusta la altura según el contenido */
  overflow-y: auto; /* Agrega un desplazamiento vertical si el contenido excede el tamaño del contenedor */
  
}

.sidebar.expanded + .contentDash {
  margin-left: 0;
  
}

.toggle-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
}

.toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 25px;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 2px;
}

.toggle-button .arrow {
  width: 12px;
  height: 12px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  margin-left: 2px;
  transform: rotate(135deg);
  transition: transform 0.3s ease;
}

.toggle-button.expanded .arrow {
  transform: rotate(-45deg);
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .dashboard {
    display: flex;
    height: 87%;
  }

  .contentDash {
    display: flex;
    flex: 1;
    padding: 3%;
    transition: margin-left 0.3s ease;
    background-color: #c0ccc39f;
    flex-grow: 1; /* Hace que el contenedor se expanda para ocupar el espacio disponible */
    padding: 3%;
  }

  .sidebar.expanded + .contentDash {
    margin-left: 0;
  }

  .sidebar { /*fondo para celular del sidebar*/
    width: 30%;
    height: 100%;
    background-color: #f2f2f2;
    transition: width 0.3s ease;
    overflow: hidden;
  }

  .sidebar.expanded {
    width: 0%;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 15%;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .sidebar.expanded ul {
    opacity: 1;
  }

  .sidebar li {
    cursor: pointer;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .toggle-container {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
  }

  .toggle-button {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #f2f2f2;
    border-radius: 50%;
    border: 2px solid #1eff00;
  }
  
  .toggle-button .arrow {
    width: 12px;
    height: 12px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    margin-left: 2px;
    transform: rotate(135deg);
    transition: transform 0.3s ease;
  }
  
  .toggle-button.expanded .arrow {
    transform: rotate(-45deg);
  }
}
