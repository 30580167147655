/* Estilos generales */
bodycamino {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
}

h3camino{
  color: #333;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

/* Estilo para el contenedor de choferes */
.chofer-containercamino {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.chofer-cardcamino {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  position: relative;
  transition: transform 0.2s;
}

.chofer-cardcamino:hover {
  transform: scale(1.05);
}

.chofer-cardcamino img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.chofer-cardcamino span {
  margin-left: 10px; 
  font-size: 16px;
  font-weight: 500;
}

buttoncamino {
  background-color: #007bff;
  color: rgb(14, 13, 13);
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto;
}

buttoncamino:hover {
  background-color: #0056b3;
}

buttoncamino:focus {
  outline: none;
}

/* Tooltip estilo */
.tooltipcamino {
  position: 'absolute';
  background-color: '#fff';
  border: '1px solid #ccc';
  padding: '10px';
  borderRadius: '5px';
  boxShadow: '0px 0px 5px rgba(0,0,0,0.2)';
  zIndex: 1000;
  top: '100%';
  left: '50%';
  transform: 'translateX(-50%)';
};

/* .tooltipcamino {
  position: absolute;
  background-color: #181616;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
} */

.tooltipcamino p {
  margin: 5px 0;
  font-size: 14px;
}

/* Spinner loader estilo */
.loading-containercamino {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Estilo para el contenedor del mapa */
.map-containercamino {
  height: 600px;
  width: 100%;
  background-color: #161111;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.no-datacamino {
  text-align: center;
  font-size: 18px;
  color: #888;
}
