.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: space-around;
}

.status-dots-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
}

.status-dot.active {
  background-color: #4caf50;
}

.centro {
  text-align: center;
}

.dashboard-box {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 5px;
}

.record-list {
  list-style: none;
  padding: 0;
}

.record-list li {
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.record-list li:hover {
  background: #f1f1f1;
}

.print-modal {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
}

.btn-close {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

.btn-close:hover {
  background-color: darkred;
}

/* Dashboard.css  }*/

.dashboard-header {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-header h2 {
  font-size: 2rem;
  color: #333;
}

.icono-header {
  margin-left: 10px;
  color: #f39c12;
  font-size: 2.5rem;
}

.dashboard-grid {
  display: flex; /* Usar flexbox para la disposición */
  flex-wrap: wrap; /* Permite que las tarjetas se envuelvan a la siguiente línea si no hay suficiente espacio */
  justify-content: flex-start; /* Alinear las tarjetas al inicio del contenedor */
  gap: 20px; /* Espacio entre las tarjetas */
  padding: 20px; /* Espacio interno del contenedor */
}

.dashboard-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.dashboard-card:hover {
  transform: translateY(-5px);
}

.dashboard-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.dashboard-card p {
  font-size: 2rem;
  color: #2ecc71;
}

.dashboard-card:nth-child(1) p {
  color: #3498db;
  font-size: 35px;
}

.dashboard-card:nth-child(2) p {
  color: #e67e22;
  font-size: 35px;
}

.dashboard-card:nth-child(3) p {
  color: #9b59b6;
  font-size: 35px;
}

.dashboard-card:nth-child(4) p {
  color: #f39c12;
  font-size: 35px;
}

.dashboard-card:nth-child(5) p {
  color: #07ff3d;
  font-size: 35px;
}

.dashboard-card:nth-child(6) p {
  color: #e74c3c;
  font-size: 35px;
}

.dashboard-rastreo p {
  background-color: #e74c3c;
  color: #000000;
  font-size: 35px;
}
.qr-input-container {
  display: flex;
  align-items: center;
}

.qr-label {
  margin-right: 10px; /* Espacio entre el label y el input */
  font-size: 30px;
  color: #000000;
}

.qr-icon {
  width: 15%; /* Ajustar el tamaño del icono QR */
  margin-right: 5px; /* Espacio entre el icono y el texto */
  font-size: 35px;
}

.qr-input {
  width: 50%;
  margin-right: 10px; /* Espacio entre el input y el botón */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1; /* Para que el input se ajuste y ocupe más espacio */
}

.search-button {
  width: 20%;
  display: flex;
  align-items: center;
  background-color: #365e47; /* Color de fondo */
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.search-icon {
  margin-right: 5px; /* Espacio entre el icono y la palabra */
  width: 30px; /* Ajustar el tamaño del icono */
}
.qr-input:focus {
  border-color: #b87f05; /* Cambio de color del borde al hacer foco */
  outline: none; /* Quitar el borde por defecto del foco */
}

.result-container {
  background-color: #f9f9f9; /* Color de fondo suave */
  border: 1px solid #ddd; /* Borde gris claro */
  border-radius: 8px; /* Bordes redondeados */
  padding: 20px; /* Espaciado interno */
  margin-top: 20px; /* Espaciado superior */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}

.result-container h3 {
  color: #333; /* Color del título */
  font-weight: bold; /* Negrita para el título */
  margin-bottom: 15px; /* Espaciado inferior */
}

.result-container h4 {
  color: #000000; /* Color del texto */
  line-height: 1.6; /* Espaciado entre líneas */
  margin: 5px 0; /* Espaciado superior e inferior */
}

.result-container strong {
  color: #3a3a3a; /* Color destacado para etiquetas */
}



.dashboard-footer {
  margin-top: 150px;
  text-align: center;
  color: #888;
  font-size: 1rem;
}


.card-list {
  display: grid; /* Usamos grid para un diseño responsivo */
  grid-template-columns: repeat(auto-fit, minmax(70%, 1fr)); /* Ajusta el ancho mínimo según tus necesidades */
  gap: 20px; /* Espacio entre las cards */
  align: center;
}

.card {
  width: 100%;
  border: 1px solid #ccc; /* Borde ligero */
  border-radius: 8px; /* Bordes redondeados */
  padding: 20px; /* Espacio interno */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
  background-color: #f9f9f9; /* Color de fondo muy claro */
}

.card h4 {
  margin-bottom: 10px; /* Espacio entre los títulos y el contenido */
}