/* src/Soporte.css */
.soporte-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1, h2 {
  color: #333;
}

.form-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-row label {
  flex: 1;
  margin-right: 10px;
}

.form-row label:last-child {
  margin-right: 0;
}

label {
  display: block;
}

label span {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"], textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 100px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.tickets-table {
  width: 100%;
  border-collapse: collapse;
}

.tickets-table th, .tickets-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.tickets-table th {
  background-color: #f2f2f2;
  color: #333;
}
