/* consulta.css */

.containerInicio {
    display: flex;
    flex-wrap: wrap;
  }
  
  .containerInicio > div {
    flex: 0 0 100%;
    padding: 10px;
  }
  
  .buttonBlue {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  } 

  h4 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .itemlastInicio {
    border: 2px solid #D1FAD0;
    background-color: #d1fad03a;
    margin-top: 20px;
  }
  
 
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    word-wrap: break-word;
  }
  
  .footer {
    position: fixed;
    bottom: 20;
    width: 100%;
    z-index: 9999;
    text-align: center;
  }

  @media (max-width: 768px) {
  
    .containerInicio {
      flex-direction: column;
      height: auto;
    }
    
    .containercontainerInicio > div {
      flex: 1 0 auto;
      padding: 10px;
    }
  
    h4 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  
    .itemlastInicio {
      margin-top: 10px;
      padding: 10px;
    }
  
    p {
      font-size: 14px;
      margin-bottom: 8px;
    }
    
  }
  