
  /* General */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.containerGenerar {
  width: 100%;
  max-width: 1200px; 
  margin: 10px auto;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Headings */
h3 {
  margin: 0;
  padding-bottom: 10px;
  font-size: 1.5em;
  color: #333;
}

/* Error and Success Messages */
.error-message, .success-message {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}

.error-message {
  color: #d9534f;
  background-color: #f2dede;
}

.success-message {
  color: #5bc0de;
  background-color: #d9edf7;
}

/* Form */
.formGenerar {
  margin: 0;
}

.form-containerGenerar {
  display: flex;
  flex-direction: column;
}

/* .form-groupGenerar {
  margin-bottom: 20px;
} */

.form-groupGenerar {
  display: flex;
  align-items: center;
  gap: 15px; /* Espacio entre los elementos */
  margin-bottom: 20px;
}

.form-groupGenerar label {
  margin: 0;
  font-weight: bold;
  color: #333;
}

.input-gris-oscuro {
  width: 150px; /* Ajusta el ancho según sea necesario */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1em;
  background-color: #f9f9f9;
}

.input-gris-oscuro::placeholder {
  color: #aaa;
}

.labelito {
  font-size: 10px;
  margin-bottom: 20px;
  margin-top: 0%;
  text-align: right;
}

.table-containerGenerar {
  width: 100%;
  margin-bottom: 00px;
}

tableGenerar {
  width: 100%;
  border-collapse: collapse;
}

tdGenerar {
  padding: 10px;
}

labelGenerar {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

inputGenerar[type="text"], input[type="email"], select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1em;
}

inputGenerar[type="text"].input-gris-oscuro {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

input[type="text"]::placeholder {
  color: #aaa;
}

/* Button Styles */
.btn-submitGenerar {
  background-color: #5bc0de;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

.btn-submitGenerar:hover {
  background-color: #31b0d5;
}

/* Modal */
.print-modal {
  width: 80%;
  max-width: 800px;
  margin: auto;
}

.print-modal .ReactModal__Content {
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  position: relative;
}

.print-modal h3 {
  margin: 0;
  text-align: center;
}

.print-modal .btn-close {
  background-color: #d9534f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.print-modal .btn-close:hover {
  background-color: #c9302c;
}

.print-modal button {
  background-color: #5bc0de;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

.print-modal button:hover {
  background-color: #31b0d5;
}

/* QR Code */
.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

  /* Estilos para la impresión */
  @media print {
    body * {
      visibility: hidden;
    }
    .print-modal, .print-modal * {
      visibility: visible;
    }
    .print-modal {
      position: absolute;
      left: 20px;
      top: 15%;
      width: 450px;
      height: 350px;
      margin: auto;
      page-break-before: always;
      page-break-inside: avoid; /* Evita saltos de página dentro del modal */
    }
  
    @page {
      size: A4;
      margin: 0;
    }
     /* Oculta los botones durante la impresión */
  button {
    display: none;
  }

/*formaulario de carga para generar etiqueta*/
.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-gris-oscuro {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.inicio-button {
  grid-column: span 2;
  padding: 10px 20px;
  background-color: #6b8e23;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.inicio-button:hover {
  background-color: #556b2f;
}

  }